import { h, render, Fragment } from 'preact'
import styled from 'styled-components'
import { Card, Loader, Status, List } from '../components'
import { colors, button } from '../style'

import { HiOutlineRefresh, HiOutlineCog, HiCheck } from 'react-icons/hi'
import { AiOutlineCloudUpload, AiOutlineFile } from 'react-icons/ai'

const PresetName = styled.div`
  flex-grow: 1;
`

const PresetPercentage = styled.div``

const Button = styled.button`
  ${button};
  background-color: rgba(255, 255, 255, 0.2);
`

export const Entry = ({ state, send }) => {
  const { context } = state
  const { entrySelected, entryDetails } = context

  const finished = (trueFn) => {
    if (entryDetails?.presets.length == 0) {
      return entryDetails?.entry.ready
    } else {
      return entryDetails?.presets.filter((p) => !trueFn(p)).length === 0
    }
  };

  const transcodeFinished = finished((p) => p.converting_progress === 100)
  const uploadFinished = finished((p) => p.upload_progress === 100)

  return (
    <Card.Wrapper>
      <Card.Inner footer>
        <Card.Header>
          <Card.Title>
            {/* <Status data={entrySelected} /> */}
            {entrySelected.name}
          </Card.Title>
          <Card.HeaderIcon>
            <AiOutlineFile size={25} />
          </Card.HeaderIcon>
        </Card.Header>
        <Card.Content>
          {entryDetails === null ? (
            <Loader />
          ) : (
            <Fragment>
              <List.Wrapper>
                <List.Title>
                  <List.TitleIcon
                    color={transcodeFinished ? colors.green : colors.purple}
                    animate={!transcodeFinished}
                  >
                    {transcodeFinished ? (
                      <HiCheck size={24} />
                    ) : (
                      <HiOutlineCog size={24} />
                    )}
                  </List.TitleIcon>
                  Transcode
                </List.Title>
                <List.Container>
                  {entryDetails.presets.map((preset) => {
                    let done = preset.converting_progress === 100
                    let pending = preset.converting_progress === 0
                    let busy = !done && !pending
                    return (
                      <List.Item>
                        <List.Status done={done}>
                          {done && <HiCheck />}
                        </List.Status>
                        <PresetName>{preset.name}</PresetName>
                        {busy && (
                          <PresetPercentage>
                            ({preset.converting_progress}%)
                          </PresetPercentage>
                        )}
                      </List.Item>
                    )
                  })}
                </List.Container>
              </List.Wrapper>
              <List.Wrapper>
                <List.Title>
                  <List.TitleIcon
                    color={uploadFinished ? colors.green : colors.blue}
                  >
                    {uploadFinished ? (
                      <HiCheck size={24} />
                    ) : (
                      <AiOutlineCloudUpload size={24} />
                    )}
                  </List.TitleIcon>
                  Upload to Origin Server
                </List.Title>
                <List.Container>
                  {entryDetails.presets.map((preset) => {
                    let done = preset.upload_progress === 100
                    let pending = preset.upload_progress === 0
                    let busy = !done && !pending
                    return (
                      <List.Item>
                        <List.Status done={done}>
                          {done && <HiCheck />}
                        </List.Status>
                        <PresetName>{preset.name}</PresetName>
                        {busy && (
                          <PresetPercentage>
                            ({preset.upload_progress}%)
                          </PresetPercentage>
                        )}
                      </List.Item>
                    )
                  })}
                </List.Container>
              </List.Wrapper>
              {entryDetails.entry.ready && (
                <List.Wrapper>
                  <List.Title>
                    <List.TitleIcon color={colors.green}>
                      <HiCheck size={24} />
                    </List.TitleIcon>
                    Ready for playback
                  </List.Title>
                </List.Wrapper>
              )}
            </Fragment>
          )}
        </Card.Content>
      </Card.Inner>
      <Card.Footer>
        <Card.FooterAction color="grey" onClick={() => send('BACK')}>
          Back
        </Card.FooterAction>
        {/* {entryDetails?.entry.ready && ( */}
        {/*   <Card.FooterAction color="disabled">Preview</Card.FooterAction> */}
        {/* )} */}
      </Card.Footer>
    </Card.Wrapper>
  )
}
