import { h, render, Fragment } from 'preact'
import { Card, Loader, EntryRow } from '../components'

import { HiOutlineRefresh } from 'react-icons/hi'

export const Entries = ({ state, send }) => {
  const { context } = state
  const { entries } = context
  return (
    <Card.Wrapper>
      <Card.Inner footer>
        <Card.Header>
          <Card.Title>Entries (5 latest)</Card.Title>
          <Card.HeaderAction>
            <HiOutlineRefresh size={20} onClick={() => send('REFRESH')} />
          </Card.HeaderAction>
        </Card.Header>
        <Card.Content>
          {state.matches('loadingEntries') ? (
            <Loader />
          ) : (
            entries.map((entry) => (
              <EntryRow data={entry} onClick={() => send('ENTRY', { entry })} />
            ))
          )}
        </Card.Content>
      </Card.Inner>
      <Card.Footer>
        <Card.FooterAction onClick={() => send('NEW_ENTRY')}>
          New Entry
        </Card.FooterAction>
      </Card.Footer>
    </Card.Wrapper>
  )
}
