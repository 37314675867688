import { h, render, Fragment } from 'preact'
import styled from 'styled-components'

const FieldWrapper = styled.div`
  position: relative;
`

const Input = styled.input`
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 6px;
  width: 100%;
  padding: 8px 15px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1),
    0 1px 1px 0px rgba(255, 255, 255, 0.15);
  color: #fff;
  font-family: 'Nunito Sans';

  &:focus {
    outline: none;
    border: none;
  }

  ${(props) => props.customStyle};
`

const Indicator = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 5px;
  width: 17px;
  padding: 0 4px;
  background-color: #4d4b52;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  ${(props) => props.customStyle};
`

export const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0 5px 15px;
`

export const FieldTitle = styled.legend`
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 10px;
  font-size: 15px;
`

export const Field = ({
  indicator,
  value,
  onChange,
  disabled = false,
  indicatorStyle,
  inputStyle,
  placeholder,
}) => {
  return (
    <FieldWrapper>
      {indicator && (
        <Indicator customStyle={indicatorStyle}>{indicator}</Indicator>
      )}
      <Input
        value={value}
        onChange={onChange}
        disabled={disabled}
        customStyle={inputStyle}
        placeholder={placeholder}
      />
    </FieldWrapper>
  )
}
