import { Machine, assign } from 'xstate'
import {
  fetchEntries,
  fetchEntry,
  createEntry,
  validateFile,
  createUpload,
  fetchAuthorizationStatus,
  requestAuthorization,
} from './api'

export const uiMachine = Machine({
  id: 'ui',
  initial: 'init',
  context: {
    token: null,
    email: '',
    entries: [],
    entrySelected: null,
    entryDetails: null,
    newEntryName: '',
    selectedFile: null,
    upload: null,
    progress: null,
  },
  states: {
    init: {
      invoke: {
        src: async (ctx, e) => {
          let token = localStorage.getItem('token')
          if (token && !token.verified) {
            throw token
          }
          if (!token) {
            throw 'unauthorized'
          }
          return token
        },
        onDone: {
          target: 'loadingEntries',
          actions: assign({
            token: (ctx, e) => e.data,
          }),
        },
        onError: [
          {
            target: 'unauthorized.validating',
            cond: (ctx, e) => e.data?.verified === false,
            actions: assign({ token: (ctx, e) => e.data }),
          },
          {
            target: 'unauthorized',
          },
        ],
      },
    },
    unauthorized: {
      initial: 'idle',
      on: {
        CHANGE_EMAIL: {
          target: 'unauthorized.idle',
          actions: [
            assign({
              email: (ctx, e) => e.value,
            }),
          ],
        },
        LOGIN: {
          target: 'unauthorized.requesting',
        },
      },
      states: {
        idle: {},
        requesting: {
          invoke: {
            src: requestAuthorization,
            onDone: {
              target: 'validating',
              actions: assign({
                token: (ctx, e) => e.data,
              }),
            },
            onError: 'error',
          },
        },
        validating: {
          on: {
            UPDATE: [
              {
                target: '#loadingEntries',
                cond: (ctx, e) => e.data.verified,
                actions: assign({ token: (ctx, e) => e.data }),
              },
              {
                actions: assign({ token: (ctx, e) => e.data }),
              },
            ],
            RESTART: {
              target: 'validating',
            },
          },
          invoke: {
            src: (ctx) => (cb) => {
              const fetchStatus = async () => {
                let data = await fetchAuthorizationStatus(ctx)
                cb({ type: 'UPDATE', data })
              }
              fetchStatus()
              const interval = setInterval(fetchStatus, 1000)

              return () => {
                clearInterval(interval)
              }
            },
          },
        },
        error: {},
      },
    },
    loadingEntries: {
      id: 'loadingEntries',
      invoke: {
        src: fetchEntries,
        onDone: {
          target: 'entries',
          actions: [
            assign({
              entries: (ctx, e) => e.data,
            }),
          ],
        },
        onError: {
          target: 'entries',
        },
      },
    },
    entries: {
      on: {
        ENTRY: [
          {
            target: 'fileUpload',
            cond: (ctx, e) => !e.entry.source_uploaded,
            actions: assign({
              entrySelected: (ctx, e) => e.entry,
            }),
          },
          {
            target: 'entry',
            actions: assign({
              entrySelected: (ctx, e) => e.entry,
            }),
          },
        ],
        NEW_ENTRY: 'newEntry',
        REFRESH: 'loadingEntries',
      },
    },
    entry: {
      id: 'entry',
      on: {
        BACK: {
          target: 'loadingEntries',
          actions: assign({
            entryDetails: null,
          }),
        },
        UPDATE: {
          actions: assign({
            entryDetails: (ctx, e) => e.data,
          }),
        },
      },
      invoke: {
        src: (ctx) => (cb) => {
          const fetchData = async () => {
            let data = await fetchEntry(ctx)
            cb({ type: 'UPDATE', data })
          }
          fetchData()
          const interval = setInterval(fetchData, 1000)

          return () => {
            clearInterval(interval)
          }
        },
      },
    },
    newEntry: {
      on: {
        BACK: 'loadingEntries',
        CREATE: {
          target: 'creatingEntry',
        },
        CHANGE_NAME: {
          actions: [
            assign({
              newEntryName: (ctx, e) => e.value,
            }),
          ],
        },
      },
    },
    creatingEntry: {
      invoke: {
        src: createEntry,
        onDone: {
          target: 'fileUpload',
          actions: assign({
            selectedEntry: (ctx, e) => e.data,
          }),
        },
      },
    },
    fileUpload: {
      on: {
        BACK: {
          target: 'loadingEntries',
          actions: assign({
            selectedFile: null,
          }),
        },
        SELECT_FILE: {
          target: 'fileUpload.validatingFile',
          actions: assign({
            selectedFile: (ctx, e) => ({
              name: e.file.name,
              size: e.file.size,
              type: e.file.type,
              uniqueIdentifier: e.file.uniqueIdentifier,
              chunks: e.chunks,
            }),
          }),
        },
        INIT_UPLOAD: 'fileUpload.startUploading',
      },
      initial: 'select',
      states: {
        select: {},
        validatingFile: {
          invoke: {
            src: validateFile,
            onDone: {
              target: 'valid',
            },
            onError: {
              target: 'invalid',
              actions: assign({
                validateError: (ctx, e) => e.data,
              }),
            },
          },
        },
        valid: {},
        invalid: {},
        startUploading: {
          invoke: {
            src: createUpload,
            onDone: {
              target: 'initiatingUpload',
              actions: assign({
                upload: (ctx, e) => e.data,
                entrySelected: (ctx, e) => e.data.entry,
              }),
            },
            onError: {
              target: 'failure',
              actions: assign({
                error: (ctx, e) => e.data,
              }),
            },
          },
        },
        initiatingUpload: {
          on: {
            UPLOAD: 'uploading',
          },
        },
        uploading: {
          on: {
            PROGRESS: {
              actions: assign({
                progress: (ctx, e) => e.progress,
              }),
            },
            DONE: {
              target: 'done',
            },
          },
        },
        done: {
          on: {
            ENTRY: '#entry',
          },
        },
        failure: {},
      },
    },
  },
})
