import { h, render, Fragment } from 'preact'
import styled, { keyframes } from 'styled-components'
import { colors, RowTitle, RowDescription } from '../style'
import { Status } from '../components'

const Wrapper = styled.div`
  display: flex;
  margin: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 5px 10px;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  ${RowTitle}
`

const Description = styled.div`
  ${RowDescription}
`

export const EntryRow = ({ data, onClick }) => {
  let description = 'Awaiting upload...'
  if (data.source_uploaded) {
    description = 'Converting...'
  }
  if (data.ready) {
    description = 'Ready for playback'
  }
  return (
    <Wrapper onClick={onClick}>
      <Status data={data} />
      <Metadata>
        <Title>{data.name}</Title>
        <Description>{description}</Description>
      </Metadata>
    </Wrapper>
  )
}
