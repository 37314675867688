import { h, render, Fragment } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import styled, { keyframes } from 'styled-components'
import {
  Card,
  Loader,
  Status,
  Field,
  FieldTitle,
  Fieldset,
  RefreshIndicator,
} from '../components'
import { colors, button } from '../style'
import { useDebounce } from '../hooks'
import { checkUnique } from '../api'

import { HiOutlinePlay, HiCheck, HiOutlineX } from 'react-icons/hi'
import { AiOutlineFileAdd } from 'react-icons/ai'

export const NewEntry = ({ state, send }) => {
  const { context } = state
  const { entrySelected, entryDetails, newEntryName } = context

  const [isLoading, setIsLoading] = useState(false)
  const [isUnique, setIsUnique] = useState(null)

  const debounceName = useDebounce(newEntryName, 500)
  useEffect(() => {
    ;(async () => {
      if (debounceName) {
        setIsLoading(true)
        setIsUnique(null)
        let unique = await checkUnique(context, debounceName)
        setIsUnique(unique)
        setIsLoading(false)
      }
    })()
  }, [debounceName])

  const onChange = (e) => {
    let value = e.target.value
    send({ type: 'CHANGE_NAME', value })
  }

  const Icon = isLoading ? RefreshIndicator : isUnique ? HiCheck : HiOutlineX

  return (
    <Card.Wrapper>
      <Card.Inner footer>
        <Card.Header>
          <Card.Title>Create an Entry </Card.Title>
          <Card.HeaderIcon>
            <AiOutlineFileAdd size={25} />
          </Card.HeaderIcon>
        </Card.Header>
        <Card.Content>
          <Fieldset>
            <FieldTitle>Name</FieldTitle>
            <Field
              indicator={<Icon />}
              value={newEntryName}
              onChange={onChange}
            />
          </Fieldset>
        </Card.Content>
      </Card.Inner>
      <Card.Footer>
        <Card.FooterAction color="grey" onClick={() => send('BACK')}>
          Back
        </Card.FooterAction>
        <Card.FooterAction
          color={isUnique ? 'default' : 'disabled'}
          onClick={() => send('CREATE')}
        >
          Create
        </Card.FooterAction>
      </Card.Footer>
    </Card.Wrapper>
  )
}
