const BASE_URL = process.env.BASE_URL
export const API_URL = `${BASE_URL}/video/v1`

const fetchWithAuth = async (url, ctx, options = {}) => {
  return await fetch(url, {
    ...options,
    headers: {
      ...(options?.headers || {}),
      Authorization: `Bearer ${ctx.token.token}`,
    },
  })
}

export const fetchEntries = async (ctx) => {
  const res = await fetchWithAuth(`${API_URL}/entries`, ctx)
  const json = await res.json()
  return json
}

export const fetchEntry = async (ctx, e) => {
  const res = await fetchWithAuth(
    `${API_URL}/entries/${ctx.entrySelected.id}`,
    ctx
  )
  const json = await res.json()
  return json
}

export const checkUnique = async (ctx, name) => {
  const res = await fetchWithAuth(`${API_URL}/entries/unique/${name}`, ctx)
  return res.status !== 400
}

export const createEntry = async (ctx, e) => {
  const res = await fetchWithAuth(`${API_URL}/entries`, ctx, {
    method: 'POST',
    body: JSON.stringify({ name: ctx.newEntryName }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const json = await res.json()
  return json
}

export const validateFile = async (ctx, e) => {
  let f = ctx.selectedFile
  const res = await fetchWithAuth(
    `${API_URL}/upload/${ctx.selectedEntry.id}/validate`,
    ctx,
    {
      method: 'POST',
      body: JSON.stringify({
        filename: f.name,
        identifier: f.uniqueIdentifier,
        parts: f.chunks,
        filesize: f.size,
        filetype: f.type,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  const json = await res.json()

  if (!json.valid) {
    throw json
  }
  return json
}

export const createUpload = async (ctx, e) => {
  let f = ctx.selectedFile
  const res = await fetchWithAuth(
    `${API_URL}/upload/${ctx.selectedEntry.id}/start`,
    ctx,
    {
      method: 'POST',
      body: JSON.stringify({
        filename: f.name,
        identifier: f.uniqueIdentifier,
        parts: f.chunks,
        filesize: f.size,
        filetype: f.type,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  const json = await res.json()
  return json
}

export const requestAuthorization = async (ctx, e) => {
  const res = await fetch(`${BASE_URL}/auth/passwordless/request`, {
    method: 'POST',
    body: JSON.stringify({
      email: ctx.email,
      label: 'web',
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const json = await res.json()
  return json
}

export const fetchAuthorizationStatus = async (ctx, e) => {
  const res = await fetchWithAuth(`${BASE_URL}/auth/passwordless/status`, ctx)
  const json = await res.json()
  return json
}
