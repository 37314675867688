import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Wrapper = styled.div`
  padding: 0px 5px;
`

export const Title = styled.h3`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
`

export const TitleIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.color};
  border-radius: 100%;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);

  svg {
    animation-name: ${(props) => (props.animate ? rotate : 'none')};
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`

export const Container = styled.ul`
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  list-style: none;
  margin: 10px 0 0 19px;
  padding-left: 34px; // 19 + 1 + 34 = same as icon + margin
`

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
`

export const Status = styled.div`
  border: 2px solid #fff;
  opacity: 0.2;
  border-radius: 6px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: ${(props) => (props.done ? '#fff' : 'transparent')};
  color: #000;
`
