import { h, render, Fragment } from 'preact'
import styled from 'styled-components'
import { Field, FieldTitle, Card, RefreshIndicator } from '../components'
import { button, buttonStyles } from '../style'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Logo = styled.div`
  background: url(/logo.svg);
  background-size: contain;
  width: 300px;
  height: 300px;
`

const Button = styled.button`
  ${button};
  ${buttonStyles.default};
  margin-top: 20px;
`

const Message = styled.p`
  color: #fff;
  font-size: 14px;
  text-align: center;
  font-family: 'Nunito Sans';
  margin-bottom: 0;
  max-width: 300px;
`

export const Authorize = ({ state, send }) => {
  const { context } = state
  const { email } = context

  const onChange = (e) => {
    let value = e.target.value
    send({ type: 'CHANGE_EMAIL', value })
  }

  return (
    <Wrapper>
      <Logo />
      {(state.matches('unauthorized.idle') ||
        state.matches('unauthorized.error')) && (
        <Fragment>
          <Form onSubmit={() => send('LOGIN')}>
            <div>
              <Field value={email} onChange={onChange} placeholder="Email" />
              {state.matches('unauthorized.error') && (
                <Message>Invalid email</Message>
              )}
            </div>
            <Button type="submit">Login</Button>
          </Form>
        </Fragment>
      )}
      {state.matches('unauthorized.requesting') && <RefreshIndicator />}
      {state.matches('unauthorized.validating') && (
        <Message>
          An email has been sent, please keep this window open and click the
          link provided in the email.
        </Message>
      )}
    </Wrapper>
  )
}
