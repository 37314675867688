import { h, render, Fragment } from 'preact'
import ContentLoader from 'react-content-loader'
import styled, { keyframes } from 'styled-components'

import { HiOutlineRefresh } from 'react-icons/hi'

export const Loader = (props) => (
  <ContentLoader
    speed={2}
    width={400}
    height={150}
    viewBox="0 0 400 150"
    backgroundColor="rgba(255,255,255,0.1)"
    foregroundColor="rgba(255,255,255,0.4)"
    {...props}
  >
    <circle cx="10" cy="20" r="8" />
    <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="50" r="8" />
    <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="80" r="8" />
    <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="110" r="8" />
    <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
  </ContentLoader>
)

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`
export const RefreshIndicator = styled(HiOutlineRefresh)`
  animation-name: ${rotate};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
`
