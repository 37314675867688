import styled from 'styled-components'
import * as styles from '../style'

export const Wrapper = styled.div`
  background-color: #3a393d;
  border-radius: 15px;
  color: #f1f1f1;
  width: 300px;
`

export const Inner = styled.div`
  padding: 20px 25px ${(props) => (props.footer ? '0' : '20px')};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
`

export const HeaderAction = styled.a`
  border-radius: 100%;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.4);
  display: block;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.8);
  }
`

export const HeaderIcon = styled.div`
  color: rgba(255, 255, 255, 0.6);
`

export const Title = styled.h2`
  ${styles.CardTitle}
  display: flex;
  align-items: center;
  margin: 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  margin: 20px 0;
`

export const Footer = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  display: flex;
  width: 100%;
  background-color: #313033;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 15px 25px;
  box-sizing: border-box;
  justify-content: space-between;
`

export const FooterAction = styled.button`
  ${styles.button}
  ${(props) => styles.buttonStyles[props.color || 'default']}
`
