import { createGlobalStyle, css } from 'styled-components'

export const Global = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Nunito Sans';
  }
`
export const CardTitle = css`
  font-weight: 600;
  font-size: 19px;
`

export const RowTitle = css`
  font-weight: 600;
  font-size: 16px;
`

export const RowDescription = css`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
`

export const colors = {
  green: '#58D6BF',
  purple: '#7F7CFF',
  yellow: '#F9C81C',
  button: '#6562FC',
  buttonHover: '#6663F1',
  blue: '#6EC8F0',
}

export const button = css`
  color: #fff;
  padding: 10px 25px;
  border-radius: 5px;
  border: none;
  box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.3),
    0px 1px 1px 0px rgba(0, 0, 0, 0.3);
  font-family: 'Nunito Sans';
  font-weight: 600;
  cursor: pointer;

  &:focus {
    outline: none;
    border: none;
  }

  &:active {
    padding: 11px 25px 9px;
    box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.3),
      inset 0px -1px 1px 0px rgba(255, 255, 255, 0.3);
  }
`

export const buttonStyles = {
  default: css`
    background-color: ${colors.button};
    &:hover {
      background-color: ${colors.buttonHover};
    }
  `,
  grey: css`
    background-color: rgba(255, 255, 255, 0.2);
    &:hover {
      background-color: rgba(255, 255, 255, 0.25);
    }
  `,
  disabled: css`
    background-color: rgba(255, 255, 255, 0.1);
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
    cursor: not-allowed;
  `,
}
