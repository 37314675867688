import { h, render, Fragment } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import styled, { keyframes, css } from 'styled-components'
import prettyBytes from 'pretty-bytes'
import Resumable from 'resumablejs'
import {
  Card,
  Loader,
  Status,
  Field,
  Fieldset,
  FieldTitle,
  RefreshIndicator,
} from '../components'
import {
  colors,
  button,
  buttonStyles,
  RowTitle,
  RowDescription,
} from '../style'
import { useDebounce } from '../hooks'
import { checkUnique, API_URL } from '../api'

import {
  HiOutlinePlay,
  HiCheck,
  HiOutlineX,
  HiOutlineRefresh,
} from 'react-icons/hi'
import { AiOutlineFileAdd, AiOutlineCloudUpload } from 'react-icons/ai'

const Button = styled.button`
  ${button};
  ${buttonStyles.grey};
  margin-bottom: 20px;
`

const Warning = styled.div`
  margin-top: 20px;
  padding: 0 5px 15px;
  font-size: 15px;
`

const FileSize = styled.span`
  font-size: 12px;
  font-weight: 600;
`

const ProgressOuter = styled.div`
  border-radius: 4px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: 6px;
`

const ProgressInner = styled.div`
  width: ${(props) => props.width};
  background-color: ${colors.green};
  height: 100%;
  border-radius: 4px;
`

const Row = styled.div`
  display: flex;
  margin: 10px 0;
  padding: 0 5px 10px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const Title = styled.div`
  ${RowTitle};
  display: flex;
  justify-content: space-between;
`

const Description = styled.div`
  ${RowDescription}
`

const Progress = (props) => {
  const percentage = Math.round(props.value * 100)
  const width = `${percentage}%`
  return (
    <ProgressOuter>
      <ProgressInner width={width} />
    </ProgressOuter>
  )
}

export const FileUpload = ({ state, send }) => {
  const { context } = state
  const { selectedFile, validateError, error, upload, progress } = context

  const [r, setR] = useState(null)
  const browseButton = useRef(null)

  // Init and de-init resumable instance
  useEffect(() => {
    const instance = new Resumable({
      target: `${API_URL}/upload`, // Will be overwritten
      method: 'octet',
      chunkSize: 15 * 1024 * 1024,
      maxFiles: 1,
      fileType: ['mp4', 'mp3', 'm4a', 'ogg'],
      headers: {
        Authorization: `Bearer ${context.token.token}`,
      },
    })
    setR(instance)

    instance.on('fileAdded', (file, event) => {
      send({ type: 'SELECT_FILE', file: file.file, chunks: file.chunks.length })
    })

    instance.on('progress', () => {
      send({ type: 'PROGRESS', progress: instance.progress() })
    })

    instance.on('fileSuccess', () => {
      send('DONE')
    })

    return () => {
      instance.cancel()
    }
  }, [])

  // Connect browse button to Resumable
  useEffect(() => {
    if (!browseButton.current || !r) {
      return
    }
    r.assignBrowse(browseButton.current)
  })

  // Trigger upload
  useEffect(() => {
    if (!state.matches('fileUpload.initiatingUpload')) {
      return
    }

    r.opts.target = `${API_URL}/upload/${upload.id}`
    r.upload()
    send('UPLOAD')
  }, [state.value])

  // Both invalid as valid allow for upload.
  // Invalid only indicates a new file has been chosen instead of resuming the previous file
  const canUpload =
    state.matches('fileUpload.invalid') || state.matches('fileUpload.valid')

  const selecting = state.matches('fileUpload.select') || canUpload

  return (
    <Card.Wrapper>
      <Card.Inner footer>
        <Card.Header>
          <Card.Title>Upload a file</Card.Title>
          <Card.HeaderIcon>
            <AiOutlineCloudUpload size={25} />
          </Card.HeaderIcon>
        </Card.Header>
        <Card.Content>
          {selecting && (
            <Fragment>
              <Button ref={browseButton}>Choose file</Button>
              {selectedFile && (
                <Fieldset>
                  <FieldTitle>File</FieldTitle>
                  <Field
                    value={selectedFile.name}
                    disabled
                    inputStyle={css`
                      padding-right: 60px;
                      text-overflow: ellipsis;
                    `}
                    indicatorStyle={css`
                      width: auto;
                    `}
                    indicator={
                      selectedFile && (
                        <FileSize>{prettyBytes(selectedFile.size)}</FileSize>
                      )
                    }
                  />
                </Fieldset>
              )}
              {state.matches('fileUpload.invalid') && (
                <Warning>
                  {`You have previously started an upload with a different file name: ${validateError.uploads[0].filename}. To continue that upload reselect the same file.`}
                </Warning>
              )}
            </Fragment>
          )}

          {(state.matches('fileUpload.startUploading') ||
            state.matches('fileUpload.initiateUploading')) && (
            <RefreshIndicator />
          )}

          {(state.matches('fileUpload.uploading') ||
            state.matches('fileUpload.done')) && (
            <Row>
              <Status
                override={
                  state.matches('fileUpload.done') ? 'ready' : 'uploading'
                }
              />
              <Metadata>
                <Title>
                  <span>Uploading</span>
                  <span>{`${Math.round(progress * 100)}%`}</span>
                </Title>
                <Progress value={progress} />
              </Metadata>
            </Row>
          )}
        </Card.Content>
      </Card.Inner>
      <Card.Footer>
        <Card.FooterAction color="grey" onClick={() => send('BACK')}>
          Back
        </Card.FooterAction>
        {state.matches('fileUpload.done') ? (
          <Card.FooterAction onClick={() => send('ENTRY')}>
            View progress
          </Card.FooterAction>
        ) : (
          <Card.FooterAction
            color={canUpload ? 'default' : 'disabled'}
            onClick={() => send('INIT_UPLOAD')}
          >
            Upload
          </Card.FooterAction>
        )}
      </Card.Footer>
    </Card.Wrapper>
  )
}
