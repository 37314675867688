import { h, render, Fragment } from 'preact'
import styled, { keyframes } from 'styled-components'
import { colors } from '../style'

import { HiCheck, HiOutlineCog } from 'react-icons/hi'
import { AiOutlineCloudUpload } from 'react-icons/ai'

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const StatusWrapper = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.color};
  border-radius: 100%;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);

  svg {
    animation-name: ${(props) => (props.animate ? rotate : 'none')};
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`

export const Status = ({ data, override }) => {
  let status = 'uploading'
  if (data?.source_uploaded) {
    status = 'converting'
  }
  if (data?.ready) {
    status = 'ready'
  }

  if (override) {
    status = override
  }

  let animate = false,
    color,
    Icon
  switch (status) {
    case 'uploading':
      color = colors.blue
      Icon = AiOutlineCloudUpload
      break
    case 'converting':
      color = colors.purple
      Icon = HiOutlineCog
      animate = true
      break
    default:
      // Ready
      color = colors.green
      Icon = HiCheck
      break
  }
  return (
    <StatusWrapper color={color} animate={animate}>
      <Icon size={24} />
    </StatusWrapper>
  )
}
