import 'regenerator-runtime/runtime'

import { h, render, Fragment } from 'preact'
import { useEffect } from 'preact/hooks'
import styled, { createGlobalStyle } from 'styled-components'
import { useMachine } from '@xstate/react'
import ContentLoader from 'react-content-loader'

import { Global } from './style'
import { Card, Loader, Entry } from './components'
import { uiMachine } from './machine'
import * as Views from './views'

// background: #1D1D1F
// dark card: #313033
// card: #3a393d

const Container = styled.div`
  background-color: #1d1d1f;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

// Get the persisted state config object from somewhere, e.g. localStorage
const persistedState =
  JSON.parse(localStorage.getItem('state')) || uiMachine.initialState

const App = () => {
  const [state, send, service] = useMachine(uiMachine, {
    state: persistedState,
  })

  useEffect(() => {
    if (
      state.matches('unauthorized.validating') &&
      !('exec' in state.activities['ui.unauthorized.validating:invocation[0]'])
    ) {
      send('RESTART')
    }
  }, [state.value])

  const { context } = state
  const { entries, selectedEntry } = context

  // Persist state
  useEffect(() => {
    service.onTransition((state) => {
      localStorage.setItem('state', JSON.stringify(state))
    })
  }, [])

  return (
    <Fragment>
      <Global />
      <Container>
        {state.matches('unauthorized') && (
          <Views.Authorize state={state} send={send} />
        )}
        {(state.matches('entries') || state.matches('loadingEntries')) && (
          <Views.Entries state={state} send={send} />
        )}
        {state.matches('entry') && <Views.Entry state={state} send={send} />}
        {state.matches('newEntry') && (
          <Views.NewEntry state={state} send={send} />
        )}
        {state.matches('fileUpload') && (
          <Views.FileUpload state={state} send={send} />
        )}
      </Container>
    </Fragment>
  )
}
render(<App />, document.getElementById('root'))
